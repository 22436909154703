import React, { useState } from 'react';
import {  Container, Typography } from '@mui/material';
import './App.css';
import Game from './components/Game/Game';
import LandingPage from './components/Landing/Landing';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Helmet } from 'react-helmet';
import orsetto from './assets/logo.png';
import { color } from 'three/webgpu';
const theme = createTheme({
  palette: {
    primary: {
      main: '#db37b4',  // Primary color for buttons
    },
    secondary: {
      main: '#dc004e',  // Secondary color for buttons
    },
  },
  typography: {
    fontFamily: 'Daypbl, Arial, sans-serif',
    h1: {
      fontFamily: 'Daypbl, Arial, sans-serif',
      color:"#db37b4",
      fontSize: 30,
    },
    h5: {
      fontFamily: 'Daypbl, Arial, sans-serif',
      color:"#db37b4",
      fontSize: 24,
    },
    h6: {
      fontFamily: 'Daypbl, Arial, sans-serif',
      color:"#db37b4",
      fontSize: 30,
    },
    body1: {
      fontFamily: 'Itchy, Arial, sans-serif',
      fontSize: 28,
      textTransform: 'uppercase',
      color:"white"
    },
    body2: {
      fontFamily: 'Itchy, Arial, sans-serif',
      fontSize: 20,
      textTransform: 'uppercase',
      color:"white"
    },
    p: {
      fontFamily: 'Itchy, Arial, sans-serif',
      fontSize: 28,
      textTransform: 'uppercase',
      color:"white"
    },
    p2: {
      fontFamily: 'Itchy, Arial, sans-serif',
      fontSize: 24,
      textTransform: 'uppercase',
      color:"white"
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'uppercase',  // Remove uppercase transformation for buttons
          fontSize: 24,
        },
        containedPrimary: {
          backgroundColor: '#db37b4', // Custom color for primary contained button
          '&:hover': {
            backgroundColor: '#db37b4', // Custom hover color for primary button
          },
        },
        containedSecondary: {
          backgroundColor: '#e57373', // Custom color for secondary contained button
          '&:hover': {
            backgroundColor: '#d32f2f', // Custom hover color for secondary button
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontFamily: 'Daypbl',
          textDecoration: "bold",
          color:"white",
          // Custom root styling (affects the container)
          '& .MuiOutlinedInput-root': {
            borderRadius: '0px',  // Example: Rounded corners for outlined input
            '&.Mui-error': {
              borderColor: 'transparent', // Change the border color when error
              color:"#db37b4",
              fontFamily: 'Daypbl',
              // margin:-10,
              '& fieldset': {
                color:"#db37b4",
                borderColor: 'transparent',
                
              },
            },
          
          '& .MuiFormHelperText-root': {
            color: 'red', // Change the helper text color
          },
            '& fieldset': {
              borderColor: 'transparent', 
              margin:0 // Initial border color
            },
            '&:hover fieldset': {
              borderColor: 'transparent',  // Border color on hover
            },
            '&.Mui-focused fieldset': {
              borderColor: 'transparent',  // Border color when focused
            },
          },
          '& .MuiInputLabel-root': {
            color: 'white',  // Label color
            fontFamily: 'Daypbl, Arial, sans-serif',
            margin:0,
            '&.Mui-focused': {
              color: 'white',  // Label color when focused
            },
          },
          
        },
      },
    },
  }
});
// Main Game Component
const App = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [isWinner, setIsWinner] = useState(false);

  const handleFormSubmit = (user) => {
    setUserInfo(user);
  };
  

  
  return (
    <>
    <Helmet>
      <link rel="amphtml" href={window.location.href}/>
      <link rel="canonical" href={window.location.href}/>
      <meta name="description" content="The game of the year!"/>
      <meta name="keywords" content="game, fun, entertainment, toyroomroma, Toy Room"/>
     {/* Google AMP Tags */}
     <link rel="amphtml" href={window.location.href} />

{/* Facebook Open Graph Tags */}
<meta property="og:type" content="website" />
<meta property="og:title" content="Get #Frankthebear" />
<meta property="og:description" content="Get #Frankthebear, il mini gioco per ottenere accesso all'evento di Toy Room Roma" />
<meta property="og:url" content={window.location.href} />
<meta property="og:image" content={orsetto} />
<meta property="og:site_name" content="Get #Frankthebear" />

{/* Instagram Meta Tags (uses Open Graph protocol) */}
<meta property="og:image" content={orsetto} />
<meta property="og:image:alt" content="Toy Room Roma" />

{/* Twitter Card Tags */}
<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:title" content="Get #Frankthebear" />
<meta name="twitter:description" content="Get #Frankthebear, il mini gioco per ottenere accesso all'evento di Toy Room Roma" />
<meta name="twitter:image" content={orsetto} />
<meta name="twitter:site" content="@toyroomroma" />
<meta name="twitter:creator" content="@ytoyroomroma" />

{/* Pinterest */}
<meta name="pinterest-rich-pin" content="true" />
<meta property="og:image" content={orsetto} />

{/* Icons and Favicons */}
<link rel="icon" type="image/png" href="/logo.png" sizes="16x16" />
<link rel="apple-touch-icon" href="/logo.png" />
    </Helmet>
    <ThemeProvider theme={theme}>
    <Container maxWidth="sm" >
      {/* <Navigation onMenuClick={handleMenuClick} /> */}
      {userInfo ? (
        <Container maxWidth="sm" >
          <br/>
          <Typography variant="h6" align="center">
            Benvenuto, {userInfo.name.toUpperCase()} {userInfo.surname.toUpperCase()}!
          </Typography>
          <Game isWinner={isWinner} setIsWinner={setIsWinner} userInfo={userInfo} />
        </Container>
      ) : (
        <LandingPage onSubmit={(user) => handleFormSubmit(user)} />
      )}
    </Container>
    </ThemeProvider>
    </>
  );
};


// CSS styles


export default App;