import React, { useState, useEffect } from 'react';
import { Button, Grid, Container, Typography, Paper, Box } from '@mui/material';
import '../../App.css';
import WinnerPage from '../Winner/Winner';
import orso from '../../assets/orsetto.png';
import bottle from '../../assets/boottiglia.png';
import bomba from '../../assets/bomba.png';
import triangolo from '../../assets/ostacolo-2-toy.png';
const Game = ({ isWinner, setIsWinner, userInfo }) => {
    const [score, setScore] = useState(0);
    const [activeItem, setActiveItem] = useState({ index: -1, type: 'NONE' });
    const [gameOver, setGameOver] = useState(false);
    const [win, setWin] = useState(false);
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [isBomb, setBomb] = useState(false);


    const getRandomItem = () => {
        const randomNum = Math.random();
        if (randomNum < 0.4) return 'MOLE';       // 60% chance to get a mole
        if (randomNum < 0.6) return 'BOMB';       // 20% chance to get a bomb
        if (randomNum < 0.8) return 'TRIANGLE';       // 20% chance to get a bomb
        return 'CHAMPAGNE';                       // 20% chance to get a champagne bottle
    };

    const getRandomHoleIndex = () => Math.floor(Math.random() * 9);

    const handleHit = () => {
        if (gameOver) return;

        const { type } = activeItem;

        if (type === 'MOLE') {
            setScore(score + 1);
        } else if (type === 'CHAMPAGNE') {
            setScore(score + 2);
        } else if (type === 'BOMB') {
            setGameOver(true); // End the game if a bomb is clicked
            return;
        }

        setActiveItem({ index: -1, type: 'NONE' });
    };

    useEffect(() => {
        if (!gameOver) {
            const interval = setInterval(() => {
                const newItem = getRandomItem();
                const newIndex = getRandomHoleIndex();
                setActiveItem({ index: newIndex, type: newItem });
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [gameOver]);
    useEffect(() => {
        if (score >= 15) {
            setWin(true);
            setGameOver(true);
        }
    }, [score]);
    const getObstacle = () => {
        if (isBomb){
            setBomb(false)
            return bomba
        }
        else {
            setBomb(true)
            return triangolo
        }
    }
    return (
        <Container maxWidth="sm" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <Paper elevation={3} sx={{ padding: 3, marginTop: 4, backgroundColor: 'rgba(255,255,255,.1)', width: '100%' }} spacing={2} justifyContent="center">
                <Typography variant="h1" gutterBottom>Get #Frankthebear</Typography>
                <Typography variant="h5">Punti: {score}</Typography>
                {gameOver && !win && <Typography variant="h6" color="error">Game Over!</Typography>}
                {!win && !gameOver && <Grid container spacing={2} py={4} justifyContent="center">
                    {Array.from({ length: 9 }).map((_, index) => (
                        <Grid item xs={4} spacing={0} key={index}>
                            <Box
                                sx={{
                                    width: 130,
                                    height: 130,
                                    // backgroundColor: '#8B4513',
                                    // border: '1px solid #8B4513',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    // borderRadius: '50%',
                                    cursor: 'pointer',
                                    fontSize: '60px',
                                }}
                                onClick={index === activeItem.index ? handleHit : null}
                                onTouchStart={index === activeItem.index ? handleHit : null}
                            >
                                {index === activeItem.index && (
                                    activeItem.type === 'MOLE' ? <img src={orso} height={180} alt='🐻' /> :
                                        activeItem.type === 'BOMB' ? <img src={bomba}  height={180} alt='Ostacolo' /> :
                                        activeItem.type === 'TRIANGLE' ? <img src={triangolo} height={160} alt='Ostacolo2' /> :
                                            <img src={bottle} height={180} alt='🍾' />
                                )}
                            </Box>
                        </Grid>
                    ))}
                </Grid>}
                {gameOver && !win && (
                    <Button
                        variant="contained"
                        color="secondary"
                        sx={{ marginTop: 2 }}
                        onClick={() => {
                            setScore(0);
                            setGameOver(false);
                        }}
                    >
                        Restart Game
                    </Button>
                )}
                {win && (
                    <WinnerPage isWinner={isWinner} userInfo={userInfo} setIsWinner={setIsWinner} isEmailSent={isEmailSent} setIsEmailSent={(status) => setIsEmailSent(status)}onRestart={() => {
                        setScore(0);
                        setGameOver(false);
                        setWin(false);
                    }} />
                )}
            </Paper>
        </Container>
    );
};

export default Game;