import  React, { useEffect } from 'react';
import { Container, Typography, Paper, Button } from '@mui/material';
import emailjs from "@emailjs/browser"
const WinnerPage = ({ onRestart ,isWinner,userInfo, isEmailSent, setIsEmailSent}) => {
    
    useEffect(() => {
        if (!isEmailSent) {
        emailjs.send('service_ek85oy7','template_n864urn',{
            name: userInfo.name,
            surname: userInfo.surname,
            email: userInfo.email
        },'bECnaJdEPsBaWYkSc').then(
            (response) => {
                alert('Email inviata!');
                setIsEmailSent(true);
            },
            (error) => {
                console.log(error);
                alert('Errore nell\'invio dell\'email!\nRiprova dopo!');
            }
        )
    }
    },[])

   return ( <Container maxWidth="sm">
      <Paper elevation={3} sx={{ padding: 3, marginTop: 4, textAlign: 'center' ,backgroundColor:"transparent" }}>
        <Typography variant="h1" gutterBottom>Congratulazioni!</Typography>
        {isWinner && <Typography variant="body1" gutterBottom>{userInfo.name} hai già vinto per oggi!</Typography> }
        {!isWinner && <Typography variant="body1" gutterBottom>
          Riceverai a breve un invito all'evento!
        </Typography>}
        <Button variant="contained" color="primary" onClick={onRestart}>
          Gioca di nuovo
        </Button>
      </Paper>
    </Container>)
};
  export default WinnerPage;